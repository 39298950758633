import * as React from "react"
import { Box, Grid, Typography, withStyles } from "@material-ui/core"
import { TextStyles } from "../styles/PartialStyles"

type Props = {
  classes: {
    centered: string
    justify: string
  }
}

const BmcIntro = ({ classes }: Props) => (
  <Grid item>
    <Box p={4}>
      <Typography variant="h4" className={classes.centered} gutterBottom>
        BMC Equipment & Tools
      </Typography>
      <Typography variant="body1" gutterBottom>
        Established over 25 years ago, BMC Equipment & Tools Ltd. based in
        Summerhill Co. Meath is a leading supplier of engineering machinery and
        tools. Irish owned, we pride ourselves on supplying professional grade,
        quality equipment to the home market and exporting it worldwide.
      </Typography>
      <Typography variant="body1" gutterBottom>
        We supply thousands of engineering machines, consumables and accessories
        across a broad section of industries, and our prices are hard to match!
      </Typography>
    </Box>
  </Grid>
)

export default withStyles(TextStyles)(BmcIntro)
