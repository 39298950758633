import React, { useRef, useState } from "react"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/effect-cube"
import "swiper/css/pagination"

// import Swiper core and required modules
import SwiperCore, { Autoplay, EffectCube, Pagination } from "swiper"
import { Grid, Typography, withStyles } from "@material-ui/core"
import { IndexCubeStyles } from "../styles/PartialStyles"

// install Swiper modules
SwiperCore.use([EffectCube, Pagination, Autoplay])

type Props = {
  classes: {
    topBar: string
    bottomBar: string
    container: string
    title: string
  }
}

const IndexCube = ({ classes }: Props) => (
  <Swiper
    effect={"cube"}
    grabCursor={true}
    cubeEffect={{
      shadow: true,
      slideShadows: true,
      shadowOffset: 20,
      shadowScale: 0.94,
    }}
    autoplay={{
      delay: 4000,
      disableOnInteraction: false,
    }}
    pagination={true}
    loop={true}
    className="mySwiper"
  >
    <SwiperSlide>
      <div className={classes.container}>
        <Grid
          container
          justify={"center"}
          alignItems={"center"}
          style={{ height: "500px" }}
        >
          <Grid item>
            <img alt="MVD ibend" src="images/iBendC.png" />
          </Grid>
        </Grid>
        <div className={classes.topBar}>
          <Grid container alignItems={"center"} style={{ height: "100%" }}>
            <Grid item>
              <Typography variant={"h5"} className={classes.title}>
                iBend C Series
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </SwiperSlide>
    <SwiperSlide>
      <div className={classes.container}>
        <Grid
          container
          justify={"center"}
          alignItems={"center"}
          style={{ height: "500px" }}
        >
          <Grid item>
            <img alt={"mvd ishear"} src="images/iShear.png" />
          </Grid>
        </Grid>
        <div className={classes.topBar}>
          <Grid container alignItems={"center"} style={{ height: "100%" }}>
            <Grid item>
              <Typography variant={"h5"} className={classes.title}>
                iShear B Series
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </SwiperSlide>
    <SwiperSlide>
      <div className={classes.container}>
        <Grid
          container
          justify={"center"}
          alignItems={"center"}
          style={{ height: "500px" }}
        >
          <Grid item>
            <img alt="fmb jupiter" src="images/jupiter.png" />
          </Grid>
        </Grid>
        <div className={classes.topBar}>
          <Grid container alignItems={"center"} style={{ height: "100%" }}>
            <Grid item>
              <Typography variant={"h5"} className={classes.title}>
                Jupiter Bandsaw
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </SwiperSlide>
    <SwiperSlide>
      <div className={classes.container}>
        <Grid
          container
          justify={"center"}
          alignItems={"center"}
          style={{ height: "500px" }}
        >
          <Grid item>
            <img alt="pp200" src="images/pp200.png" />
          </Grid>
        </Grid>
        <div className={classes.topBar}>
          <Grid container alignItems={"center"} style={{ height: "100%" }}>
            <Grid item>
              <Typography variant={"h5"} className={classes.title}>
                Nargesa PP200
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </SwiperSlide>
    <SwiperSlide>
      <div className={classes.container}>
        <Grid
          container
          justify={"center"}
          alignItems={"center"}
          style={{ height: "500px" }}
        >
          <Grid item>
            <img alt="hydra" src="images/HYDRA110.png" />
          </Grid>
        </Grid>
        <div className={classes.topBar}>
          <Grid container alignItems={"center"} style={{ height: "100%" }}>
            <Grid item>
              <Typography variant={"h5"} className={classes.title}>
                Geka Hydracrop 110
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </SwiperSlide>
  </Swiper>
)

export default withStyles(IndexCubeStyles)(IndexCube)
