import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import { Grid } from "@material-ui/core"
import IndexCube from "../components/IndexCube"
import BmcIntro from "../components/BmcIntro"
import { Capitalize, PageHeader } from "../components/partials"
import { navigate } from "gatsby"

export const HoverImage = props => (
  <img {...props} alt="company logo" style={{ cursor: "pointer" }} />
)
const IndexPage = () => (
  <Layout
    HeaderComponent={
      <PageHeader
        id={"indexHeader"}
        header={Capitalize("BMC Equipment & Tools")}
        description={"Leading supplier of engineering machinery and tooling"}
      />
    }
    hasFeatured={true}
  >
    <Seo title="Home" />
    <Grid container spacing={4}>
      <Grid
        item
        container
        xs={12}
        md={12}
        alignItems={"center"}
        justify={"center"}
        style={{ textAlign: "center" }}
      >
        <Grid item xs={6} md={3}>
          <HoverImage
            src="images/fmb-logo.png"
            onClick={() => navigate("/catalog/cat?company=fmb")}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <HoverImage
            src="images/geka-logo.png"
            onClick={() => navigate("/catalog/cat?company=geka")}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <HoverImage
            src="images/sahinler-logo.png"
            onClick={() => navigate("/catalog/cat?company=sahinler")}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <HoverImage
            src="images/mvd-logo.png"
            onClick={() => navigate("/catalog/cat?company=mvd")}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <IndexCube />
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={6}
        alignItems="center"
        justifyContent="center"
      >
        <BmcIntro />
      </Grid>
    </Grid>
  </Layout>
)

export default IndexPage
